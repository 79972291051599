import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import styles from "../styles/resetPasswordNotification.module.css"


export default function ResetPasswordNotification() {

	return (
		<Layout isNavigationTransparent={true} isHomePage={false}>
			<SEO
				title="Reset Password"
				description="Poirot Password Reset Notification"
				keywords={[
					`Poirot`,
					`Email Lookup Tool`,
					`Email Finder`,
					`Email Checker`,
				]}
			/>
			<div className={styles.headerBlockWrapper}>
				<div className="sectionWrapper1200">
					<div className={styles.headerBlock}>
						<div className={styles.resetPasswordNotificationHeaderMessage}>
							<h1>Password changed.</h1>
							<h2>You can now use your new password to sign in to your account.</h2>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.emailConfirmationEmptySpace}>
			</div>
		</Layout>
	)
}
